
<!--
- Render space header
- Render grid
- pass catchRowClicked
- show-hide Footer loader
- show-hide Load more button
-->

<template>

    <template v-if="!spaceHidden">
        <div class="data" :class="!data.fetched.length && 'empty'">
            <table class="space" :class="[attrs.space, attrs.uid, attrs.singleRow?'single':'']" @dragenter.prevent="$emit('filesDropping')" @dragover.prevent="$emit('filesDropping')" @dragleave.prevent="$emit('filesDroppingLeft')">
                <thead>
                <SpaceHeader
                    ref="spaceHeader"
                    v-if="(!attrs.headerWaitsForData||data.shown.length)&&!attrs.doNotShowSpaceHeader"
                    :attrs="attrs"
                    :setting="setting"
                    :columns="columns"
                    :searchData="searchData"
                    :sortData="sortData"
                    :data="data"
                    :addWatcher="addWatcher"
                    @add="addRow"
                    @magicEditor="openUniversalManager"
                    @clearSearch="clearSearch"
                    @showTemplates="showTemplates"
                    @filesDropped="addRowAndUploadFiles"
                    @rowsSelectionChanged="$emit('rowsSelectionChanged')"
                    @run="run"
                />
                </thead>

                <!--
                       @catchRowClicked $event represents row ref objects
                -->
                <SpaceGrid
                    :loader="loader"
                    :attrs="attrs"
                    :setting="setting"
                    :columns="columns"
                    :data="data"
                    @catchRowClicked="$emit('catchRowClicked', {
                        ...$event,
                        data
                    })"
                    @run="run"
                    @rowsSelectionChanged="$emit('rowsSelectionChanged')"
                    @rowSelected="$emit('rowSelected', $event)"
                    @update="update"
                    @loadMoreData="loadMoreData"
                    @filesDropped="uploadFiles"
                    @contextmenu="showContextmenu"
                />

            </table>

            <DataFooterWaiter v-if="loader.footerLoader&&!loader?.noData" :setting="attrs"/>

            <button
                v-if="(setting.loader?.type==='button' || attrs.loaderType==='button')&&!loader?.noData&&!loader?.noMoreData&&data.shown.length"
                class="btn__gold"
                @click="loadMoreData"
            >
                Load more
            </button>

            <div class="no-data" v-if="loader.noData&&!data.shown.length">
                <span>No data available</span>
            </div>
            <div class="no-data" v-if="setting.loader.type!=='all' && !attrs.singleRow && loader.noMoreData">
                <span>No more data available</span>
            </div>
            <div :class="[error.code === 404 && 'no-data', error.code !== 404 && 'error-label']" v-if="error.message">
                <span>{{ error.message }}</span>
            </div>

        </div>

    </template>

    <Teleport to="#internal">
        <ContextMenu
            v-if="contextMenu.open"
            :contextMenu="contextMenu"
            @dataTouched="$emit('dataTouched')"
            @run="run"
            @close="contextMenu.open = false"
        />

        <TransactionManager
            ref="transactionManagerRef"
            v-if="transactionManager"
            :attrs="transactionManager.attrs"
            :setting="transactionManager.setting"
            :row="transactionManager.row"
            :id="transactionManager.id"
            :files="transactionManager.files"
            :fileRow="transactionManager.fileRow"
            @dataTouched="$emit('dataTouched')"
            @close="transactionManager = null"
        />

        <FileManager
            ref="fileManagerRef"
            v-if="fileManager"
            :attrs="fileManager.attrs"
            :setting="fileManager.setting"
            :row="fileManager.row"
            :files="fileManager.files"
            @dataTouched="$emit('dataTouched')"
            @close="fileManager = null"
        />

        <ProjectManager
            v-if="projectManager"
            :attrs="attrs"
            :row="projectManager.row"
            @dataTouched="$emit('dataTouched')"
            @close="projectManager = null"
        />

        <AccountManager
            v-if="accountManager"
            :setting="accountManager.setting"
            :attrs="accountManager.attrs"
            :row="accountManager.row"
            :parentRow="accountManager.parentRow"
            @update="update"
            @close="accountManager = null"
        />

        <CommentManager
            v-if="commentManager"
            :attrs="commentManager.attrs"
            :setting="commentManager.setting"
            :row="commentManager.row"
            :thread="commentManager.thread"
            :data="data"
            @update="update"
            @close="closeCommentManager"
            @run="run"
        />

        <BankManager
            v-if="bankManager"
            :row="bankManager.row"
            :data="data"
            @close="bankManager = null"
            @run="run"
            @update="update"
        />

        <InvoicingStatuses
            v-if="invoicingStatuses.transactions&&invoicingStatuses.open"
            :attrs="invoicingStatuses.attrs"
            :transactions="invoicingStatuses.transactions"
            :invoicingStatuses="invoicingStatuses"
            @close="closeInvoicingStatuses"
            @run="run"
        />

        <InvoicingDialog
            v-if="invoicingDialog.open"
            :invoicingDialog="invoicingDialog"
            @close="invoicingDialog.open = false"
        />

        <IncomeRequestManager
            ref="incomeRequestManagerRef"
            v-if="requestManager && requestManager.type === 'income'"
            :attrs="requestManager.attrs"
            :row="requestManager.row"
            :id="requestManager.id"
            @close="requestManager = null"
            @run="run"
            @update="update"
        />

        <ObligationRequestManager
            v-if="requestManager && requestManager.type === 'obligation'"
            :attrs="requestManager.attrs"
            :row="requestManager.row"
            :id="requestManager.id"
            @close="requestManager = null"
            @run="run"
            @update="update"
        />

        <TemplateManager
            v-if="templateManager.open"
            :templateManager="templateManager"
            @useTemplate="useTemplate"
        />

        <ObligationRequestCreator
            v-if="invoiceRequest && invoiceRequest.type === 'obligation'"
            :invoiceRequest="invoiceRequest"
            :row="invoiceRequest.row"
            :attrs="invoiceRequest.attrs"
            :setting="invoiceRequest.setting"
            @run="run"
            @update="update"
            @close="closeInvoiceRequest"
        />

        <IncomeRequestCreator
            v-if="invoiceRequest && invoiceRequest.type === 'income'"
            :invoiceRequest="invoiceRequest"
            :transaction="invoiceRequest.transaction"
            :file="invoiceRequest.file"
            :attrs="invoiceRequest.attrs"
            :setting="invoiceRequest.setting"
            @run="run"
            @update="update"
            @close="closeInvoiceRequest"
        />

        <CreateContactGuide
            v-if="createContactGuide.open"
            :attrs="createContactGuide.attrs"
            @close="createContactGuide.open = false"
            @add="addRow"
        />

    </Teleport>

</template>

<script>

import {inject, reactive, ref, watch, watchEffect, defineExpose, defineAsyncComponent, onMounted} from 'vue'
import CreateContactGuide from "@/components/tools/CreateContactGuide.vue";
import fileService from "@/services/FileService";
import services from "@/app/Services";
export default {
    name: "SpaceBuilder",
    components: {
        TransactionManager: defineAsyncComponent(() => import('@/components/tools/TransactionManager.vue')),
        BankManager: defineAsyncComponent(() => import('@/components/tools/BankManager.vue')),
        CommentManager: defineAsyncComponent(() => import('@/components/tools/CommentManager.vue')),
        ProjectManager: defineAsyncComponent(() => import('@/components/tools/ProjectManager.vue')),
        FileManager: defineAsyncComponent(() => import('@/components/tools/FileManager.vue')),
        ContextMenu: defineAsyncComponent(() => import('@/components/space/grid/ContextMenu.vue')),
        SpaceHeader: defineAsyncComponent(() => import('@/components/space/header/SpaceHeader.vue')),
        SpaceGrid: defineAsyncComponent(() => import('@/components/space/grid/SpaceGrid.vue')),
        DataFooterWaiter: defineAsyncComponent(() => import('@/components/elements/waiter/DataFooterWaiter.vue')),
        InvoicingStatuses: defineAsyncComponent(() => import('@/components/dialog/InvoicingStatuses.vue')),
        InvoicingDialog: defineAsyncComponent(() => import('@/components/dialog/InvocingDialog.vue')),
        IncomeRequestCreator: defineAsyncComponent(() => import('@/components/tools/InvoiceRequest/Income/IncomeRequestCreator.vue')),
        IncomeRequestManager: defineAsyncComponent(() => import('@/components/tools/InvoiceRequest/Income/IncomeRequestManager.vue')),
        ObligationRequestCreator: defineAsyncComponent(() => import('@/components/tools/InvoiceRequest/Obligation/ObligationRequestCreator.vue')),
        ObligationRequestManager: defineAsyncComponent(() => import('@/components/tools/InvoiceRequest/Obligation/ObligationRequestManager.vue')),
        TemplateManager: defineAsyncComponent(() => import('@/components/tools/TemplateManager.vue')),
        CreateContactGuide: defineAsyncComponent(() => import('@/components/tools/CreateContactGuide.vue')),
        AccountManager: defineAsyncComponent(() => import('@/components/tools/AccountManager.vue'))
    },
    emits: [
        'rowsSelectionChanged',
        'rowSelected',
        'catchRowClicked',
        'dataTouched',
        'dataFetched',
        'run',
        'filesDropping',
        'filesDroppingLeft',
        'filesDropped',
    ],
    props: [
        'attrs',
        'preFetchedData'
    ],
    setup(props, {emit}){

        /** @type {SmartEnv} */
        const env = inject('env')

        /** @type {Capi}*/
        const capi = inject('Capi')

        /** @type {Dialog}*/
        const dialog = inject('Dialog')

        /** @type {Loader}*/
        const waiter = inject('Waiter')

        /** @type Utilities */
        const utilities = inject('Utilities')

        /** @type TransactionService */
        const transactionService = inject('TransactionService')

        /** @type ContactService */
        const contactService = inject('ContactService')

        /** @type DriveService */
        const driveService = inject('DriveService')

        /** @type InvoicingService */
        const invoicingService = inject('InvoicingService')

        /** @type FileService */
        const fileService = inject('FileService')

        /** @type Google */
        const google = inject('Google')

        const spaceHidden = ref(false)

        /** @type SpaceSetting */
        let setting = utilities.space.getSpaceSetting(props.attrs)

        let templateManager = reactive({open: false})
        let createContactGuide = reactive({})
        let projectManager = ref()
        let accountManager = ref()
        let fileManager = ref()
        let transactionManager = ref()
        let bankManager = ref()
        let requestManager = ref()
        let commentManager = ref()
        let contextMenu = reactive({open: false})
        let invoiceRequest = ref()
        let spaceHeader = ref()
        let lastFetchedTime = ref()
        let invoicingStatuses = reactive({})
        let invoicingDialog = reactive({})
        let toggleRows = ref(false)
        let incomeRequestManagerRef = ref()

        props.attrs.uid = Date.now().toString()

        function closeCommentManager(){
            commentManager.value = null
            resetHistoryState()
        }

        function closeInvoiceRequest(){
            invoiceRequest.value = null
        }

        function closeInvoicingStatuses(){
            invoicingStatuses.open = false
            invoicingStatuses.message = null
        }

        function resetHistoryState(){
            history.pushState(null, null, `${process.env['VUE_APP_APP_ROOT']}${props.attrs.service}`) // todo handle if certain space is selected
        }

        let loader = reactive({
            noMoreData: false,
            noData: false
        })

        let data = reactive({
            fetched: props.preFetchedData?props.preFetchedData:[],
            shown: [], // todo maybe ignore prefetched data and parse shown data manually
            original: props.preFetchedData?props.preFetchedData:[],
            indexDefaultValue: setting.loader.span.first,
            index: setting.loader.span.first
        })

        if(props.preFetchedData){
            distributeFetchedData()
        }

        let searchData = ref({})
        let sortData = reactive({
            /** @type SmartColumn */column: {},
            type: null
        })
        let addWatcher = ref(false)
        let error = reactive({
            message: null,
            code: null
        })

        waiter.hide()
        loader.footerLoader = setting.loader?.type === 'auto';
        loader.noMoreData = false

        let columns = env.configuration['space_column'].filter(column=>{
            return column.space === props.attrs.space && column.visible && Number(env.login.user['level'])<=Number(column['level']) && (props.attrs.spaceLocation!=='tool' || column['space_column_visible_in_tool']) && (!props.attrs.hiddenColumns || !props.attrs.hiddenColumns.includes(column.property)) && (!props.attrs.whitelistColumns || props.attrs.whitelistColumns.includes(column.property))
        })

        let allColumns = env.configuration['space_column'].filter(column=>{
            return column.space === props.attrs.space
        })

        // fetchData watchers
        watch(() => env.crew_id, ()=>{
            fetchData()
        })
        watch(() => env.company, () => {
            if (setting.companyScope && !props.attrs.ignoreScopes && !props.attrs.ignoreScopesReFetch) fetchData()
        })
        watch(() => env.volume, () => {
            if (setting.volumeScope && !props.attrs.ignoreScopes && !props.attrs.ignoreScopesReFetch) fetchData()
        })
        watch(() => props.attrs.currency, () => fetchData())
        watch(() => props.attrs.member, () => fetchData())

        /**
         * Run methods
         * Throwing errors method run using this function, not directly
         * @param method
         * @param contextMenu
         * @param row
         * @param rest
         */
        function run({method, contextMenu, row,  ...rest}){
            // if method exist we will run it
            if(methods[method]){
                try{
                    let promise = methods[method]({...rest, row, contextMenu})
                    if(promise instanceof Promise){
                        promise
                            .catch((e)=>{
                                if(contextMenu) {
                                    contextMenu.open = false
                                    row.selected = false
                                }
                                dialog.error(e)
                            })
                    }
                }catch (e) {
                    if(contextMenu) {
                        contextMenu.open = false
                        row.selected = false
                    }
                    dialog.error(e)
                }
            }
            // if not, we will emit it to parent
            else{
                emit('run', {
                    ...rest,
                     method, data, contextMenu, row
                })
            }
        }

        /**
         * Do not run directly methods throwing errors
         * @type {{requestInvoice({row: *, contextMenu: *, attrs: *, setting: *}): void, openItemManager({row: *, setting: *, attrs: *, contextMenu: *}): void, openFile({row: *}): void, openBankManager({row: *}): void, showPayments({row: *, attrs: *}): void, openCommentManager({row: *, setting: *, thread: *, attrs: *, contextMenu: *}): void, openTransactionManager({row: *, setting: *, files: *, attrs: *, contextMenu: *}): void, openAccountsManager({row: *, attrs: *, setting: *}): void, openCreateContactGuide({method: *, attrs: *, setting: *, row: *}): void, openFileManager({row: *, setting: *, files: *, attrs: *}): void, openDriveFolder({row: *}): void, openProjectManager({row: *}): void, reissueInvoice({attrs: *, setting: *, row: *}): void}}
         */
        const methods = {
            openFile({row}){
                if(!row.driveId) throw new Error('File Id is not defined')
                window.open("https://drive.google.com/file/d/"+row.driveId+"/");
            },
            openDriveFolder({row}){
                if(!row.driveFolder) throw new Error('Folder ID is not defined')
                window.open("https://drive.google.com/drive/u/0/folders/"+row.driveFolder+"/");
            },
            openProjectManager({row}){
                projectManager.value = {row}
            },
            openTransactionManager({row, setting, files, attrs, contextMenu, fileRow, id}){
                if(contextMenu){
                    contextMenu.open = false
                    utilities.deselectAllRows(contextMenu.data)
                }
                transactionManager.value = {row, files, setting, attrs, fileRow, id}
            },
            openTransactionManagerFromFile({row, setting, files, attrs}){
                setting = utilities.space.getSpaceSetting({space: 'transaction'}) // must get transaction space setting
                transactionService.getOne(row.parentId, attrs)
                    .then((transactionRow)=>{
                        this.openTransactionManager({row: transactionRow, setting, files, attrs, fileRow: row})
                    })
            },
            openTransactionManagerOnFiles({row, setting, files, attrs, contextMenu}){
                attrs.openTab = 'file'
                if(contextMenu){
                    contextMenu.open = false
                    utilities.deselectAllRows(contextMenu.data)
                }
                transactionManager.value = {row, files, setting, attrs}
            },
            openTransactionManagerOnPayments({row, setting, files, attrs, contextMenu}){
                attrs.openTab = 'payment'
                if(contextMenu){
                    contextMenu.open = false
                    utilities.deselectAllRows(contextMenu.data)
                }
                transactionManager.value = {row, files, setting, attrs}
            },
            openTransactionManagerOnItems({row, setting, files, attrs, contextMenu}){
                if(contextMenu){
                    contextMenu.open = false
                    utilities.deselectAllRows(contextMenu.data)
                }
                if(attrs.fromTransactionManager){
                    emit('run', {method: 'openTransactionManagerOnItems'})
                }
                else{
                    attrs.openTab = 'item'
                    transactionManager.value = {row, files, setting, attrs}
                }
            },
            openFileManager({row, setting, files, attrs}){
                fileManager.value = {row, files, setting, attrs}
            },
            openCommentManager({row, setting, thread, attrs, contextMenu}){
                if(contextMenu){
                    contextMenu.open = false
                    utilities.deselectAllRows(contextMenu.data)
                }
                commentManager.value = {row, setting, thread, attrs}
            },
            openBankManager({row}){
                bankManager.value = {row}
            },
            openRequestManager({row, attrs, type}){
                requestManager.value = {row, attrs, type}
            },
            openIncomeRequestManager({row, attrs, id}){
                requestManager.value = {row, attrs, id, type: 'income'}
            },
            openObligationRequestManager({row, attrs, id}){
                requestManager.value = {row, attrs, id, type: 'obligation'}
            },
            requestObligationInvoice({row, contextMenu, attrs, setting}){
                if(contextMenu){
                    contextMenu.open = false
                    utilities.deselectAllRows(contextMenu.data)
                }
                invoiceRequest.value = {row, attrs, setting, type: 'obligation'}
            },
            requestIncomeInvoice({transaction, file, contextMenu, attrs, setting}){
                if(contextMenu){
                    contextMenu.open = false
                    utilities.deselectAllRows(contextMenu.data)
                }
                invoiceRequest.value = {transaction, file, attrs, setting, type: 'income'}
            },
            openAccountsManager({row, attrs, setting}){
                accountManager.value = {row, attrs, setting}
            },
            manageAccounts({row, attrs, setting, contextMenu}){
                if(!row.contact) {
                    throw new Error('No contact is selected.</br> Please select contact and try again.')
                }
                return contactService.getOne(row.contact, attrs)
                    .then((contact)=>{
                        if(contextMenu) {
                            contextMenu.open = false
                            row.selected = false
                        }
                        if(row['contact_account_id']){
                            attrs.selectedRowId = row['contact_account_id']
                        }
                        accountManager.value = {row: contact, parentRow: row, attrs:
                                {
                                    ...attrs,
                                    singleRowSelection: true
                                }, setting}
                    })
            },
            openCreateContactGuide({method, attrs, setting, row}){
                Object.assign(createContactGuide, {
                    open: true, attrs
                })
            },
            reissueInvoice({attrs, setting, row}){
                dialog.loading('Reissuing invoice');
                invoicingService.reissueInvoice(row.id, setting)
                    .then((response)=>{
                        dialog.success('Invoice updated')
                        Object.assign(row, response.item)
                        driveService.relocateFile(setting, row.id)
                            .catch(dialog.nonCritical)
                    })
                    .catch(dialog.error)
            },
            createContract({attrs, setting, row, contextMenu, ...rest}){
                if(attrs.fromTransactionManager){
                    emit('run', {
                        method: 'createContract',
                        attrs, setting, row, contextMenu, ...rest
                    })
                }
                else{
                    methods.openTransactionManagerOnFiles({
                    attrs: {
                        ...attrs,
                        action: 'createContract'
                    }, setting, row, contextMenu, ...rest})
                }
            },
            addFile({row, attrs, files,  ...rest}){
                if(files.length>1) throw new Error('Only one file can be associated with file record.</br>Please drop one file only.')
                attrs.addFile = true
                if(row.driveId){
                    dialog.prompt('Row already contains file.</br>Do you want to replace it?')
                    let promptWatcher = watch((env.dialog), function({hook}){
                        promptWatcher()
                        if(hook){
                            methods.emitFilesOrOpenTransactionManagerFromFile({row, attrs, files,  ...rest})
                        }
                        else{
                            dialog.close()
                            close()
                        }
                    })
                }
                else{
                    methods.emitFilesOrOpenTransactionManagerFromFile({row, attrs, files,  ...rest})
                }
            },
            emitFilesOrOpenTransactionManagerFromFile({row, attrs, files,  ...rest}){
                if(attrs.fromTransactionManager || attrs.fromFileManager){
                    emit('filesDropped', {row, attrs, files, ...rest})
                }
                else{
                    methods.openTransactionManagerFromFile({row, attrs, files, ...rest})
                }
            },
            selectRowsForInvoicing(){
                toggleRows.value = !toggleRows.value
                data.shown.forEach(row=>{
                    if(row.status==='for-invoicing') row.selected = toggleRows.value
                })
                if(toggleRows.value && !utilities.getSelectedRows(data).length) {
                    dialog.error('There are no transactions for invoicing')
                    toggleRows.value = false
                }
            },
            issueInvoice({attrs, data, ...rest}){
                issueInvoices({
                    attrs,
                    data: {
                        ...data,
                        file_file_type_id: 1
                    },
                    ...rest
                })
            },
            issueProforma({attrs, data,  ...rest}){
                issueInvoices({
                    attrs,
                    data: {
                        ...data,
                        file_file_type_id: 4
                    },
                    ...rest})
            },
            approveFile({attrs, row, e, wait}){
                if(!row['transaction_id']){
                    throw new Error('The request is not paired with transaction.<br>Pair the request with a transaction and try it again.')
                }

                utilities.preserveElementWidth(e.target)
                wait.value = true

                fileService.approveFile(row.id, attrs)
                    .then(({updated})=>{
                        Object.assign(row, updated)
                        emit('dataTouched')
                    })
                    .catch(dialog.error)
                    .finally(()=>{
                       wait.value = false
                    })
            },
            sendForApproval({e, attrs, setting,  row, wait}){

                function openRequestCreator(){
                    wait.value = true
                    return transactionService.getOne(row.parentId, attrs)
                        .then((transaction)=>{
                            methods.requestIncomeInvoice({transaction, attrs, setting, file: row})
                        })
                        .finally(()=>{
                            wait.value = false
                        })
                }

                utilities.preserveElementWidth(e.target)
                let requestId = row['file_income_request_id']
                if(requestId){
                    wait.value = true
                    return invoicingService.getIncomeRequest(requestId, attrs)
                        .then((request)=>{
                            if(request.status === 'approved'){
                                dialog.prompt('Request is already approved. <br> Do you want to revert it to pending by sending new invoice?')
                                let promptWatcher = watch((env.dialog), function({hook}){
                                    promptWatcher()
                                    if(hook){
                                        return openRequestCreator()
                                    }
                                    else{
                                        dialog.close()
                                        close()
                                    }
                                })
                            }
                            else{
                                return openRequestCreator()
                            }
                        })
                        .finally(()=>{
                            wait.value = false
                        })
                }
            },
            reloadIncomeManagerBuilders(){
                if(requestManager.value && requestManager.value.type === 'income'){
                    incomeRequestManagerRef.value.reloadBuilders()
                }
            },
            openProjectExpensesBox({row, attrs, setting, contextMenu}){
                if(contextMenu) contextMenu.open = false
                if(row['project_hash']){
                    window.open(process.env.VUE_APP_APP_ROOT+'public/project/'+row['project_hash'])
                }
                else{
                    dialog.error('This project has no HASh defined')
                }
            }
        }

        function issueInvoices({attrs, transactions, data: sendData, callback}){
            if(!transactions && !utilities.isAnyRowSelected(data)){
                dialog.error('No rows selected')
                return
            }

            transactions = transactions?transactions:utilities.getSelectedRows(data);
            Object.assign(invoicingStatuses, {
                open: true,
                attrs,
                transactions
            });

            (function issueInvoice(index = 0){
                let transaction = transactions[index]
                transaction.class = 'loading'
                invoicingService.checkForObligatedProperties(transaction)
                    .then((transaction)=>{
                        fileService.getTransactionIssuedInvoices(transaction.id, attrs)
                            .then((files)=>{
                                // there are invoices or proformas
                                if(files){

                                    let invoices = files.filter(file => file.fileType === 1)
                                    let proformas = files.filter(file => file.fileType === 4)
                                    function dialoger(){

                                        Object.assign(invoicingDialog, {
                                            open: true,
                                            files,
                                            invoices,
                                            proformas,
                                            type: 'invoicing-dialog',
                                            documentType: sendData['file_file_type_id'],
                                            hook: undefined
                                        })

                                        let hooker = watch((invoicingDialog), function({hook}){
                                            if(hook !== undefined){
                                                if(hook) {
                                                    createInvoice(invoicingDialog.parentFile)
                                                }else {
                                                    transaction.class = 'cancelled'
                                                    dialog.close()
                                                    next()
                                                }
                                                hooker()
                                            }
                                        })
                                    }
                                    // there are existing invoices or proformas
                                    if((invoices && invoices.length) || (proformas && proformas.length)){
                                        dialoger()
                                    }
                                }
                                else{
                                    createInvoice()
                                }
                            })
                    })
                    .catch((e)=>{
                        console.log('e', e)
                        transaction.class = 'failed'
                        transaction.failMessage = e.message
                    })


                function createInvoice(parentFile = null){
                    if(parentFile) sendData.parentFileId = parentFile.id
                    transaction.class = 'creating'
                    invoicingService.createInvoice(transaction.id, sendData)
                        .then((response)=>{ // todo return transaction in response

                            driveService.relocateFile(attrs, response.item.id) // do not wait for response
                                .catch((e)=>dialog.nonCritical(e))

                            transactionService.getOne(transaction.id)
                                .then((item)=>{
                                    Object.assign(transaction, item)
                                    transaction.failMessage = null
                                    transaction.selected = false
                                    transaction.class = 'success'
                                    transaction.invoice = response.item
                                })
                                .catch((e)=>console.log(e))

                        })
                        .catch((e)=>{
                            transaction.class = 'failed'
                            transaction.failMessage = e.message
                        })
                        .finally(()=>{
                            next()
                        })
                }

                function next(){
                    index++;
                    if(index<transactions.length){
                        issueInvoice(index);
                    }
                    else{
                        callback()
                    }
                }
            })();
        }

        /**
         *
         * @param reFetch
         * @param removeOriginalFirst
         * @param fetchRequestTime
         * @param additionalData
         * @param doNotSwapTimerRow
         */
        function fetchData(reFetch = false, removeOriginalFirst = false, additionalData = null, fetchRequestTime = null, doNotSwapTimerRow = false){
            loader.noData = false
            error.message = null
            data.index = data.indexDefaultValue

            props.attrs.doNotSwapTimerRow = doNotSwapTimerRow

            if(!reFetch || removeOriginalFirst){
                data.fetched = []
                data.shown = []
                loader.footerLoader = true
                loader.noMoreData = false
            }

            let sendData = {
                property: [],
                value: []
            }

            if(setting.volumeScope && env.volume && !props.attrs.ignoreScopes){
                sendData.property.push('filter_volume')
                sendData.value.push(env.volume)
            }

            if(setting.companyScope && env.company && !props.attrs.ignoreScopes){
                sendData.property.push('filter_company')
                sendData.value.push(env.company)
            }

            return capi.get({
                ...props.attrs,
                additionalData: additionalData,
                additionalDataPresents: additionalData?true:null,
                path: props.attrs.path?props.attrs.path:(setting.volumeScope&&env.volume)||(setting.companyScope&&env.company)?'filter':'get'
            }, sendData)
                .then((rows)=>{
                    // if current fetch request time is older than last fetch time, do not use fetched data
                    if(fetchRequestTime && lastFetchedTime.value && fetchRequestTime<lastFetchedTime.value) return
                    if(fetchRequestTime) lastFetchedTime.value = fetchRequestTime

                    if(rows){
                        loader.noData = false
                        data.original = rows;

                        // Check if searchData are filled
                        if(Object.keys(searchData.value).length || sortData.column.property){
                            searchAndSort()
                        }
                        else{
                            if( !Array.isArray(rows) ) rows = [rows] // handle request fetched as objects instead of array
                            if(reFetch){
                                // if we call fetch from refetch, we must check rows for previous alerts adn currentValues
                                for(let row of data.shown){
                                    if(row.alerts){
                                        rows.filter((newRow)=>{
                                            if(newRow.id===row.id){
                                                newRow.alerts = row.alerts
                                                newRow.currentValue = row.currentValue
                                            }
                                        })
                                    }
                                }
                            }
                            data.fetched = rows;
                            data.fetchedOriginal = utilities.copyObject(data.fetched)
                            /*let limiter = (setting.loader?.type==='all' || props.attrs.loaderType==='all'/!* || props.attrs.spaceLocation==='tool'*!/)?rows.length:data.indexDefaultValue
                            data.shown = data.fetched.slice(0, limiter)*/
                            distributeFetchedData()
                            emitFetchedData()
                        }

                    }
                    else{
                        loader.noData = true
                        emitFetchedData()
                    }

                    error.message = null
                    loader.footerLoader = false
                    waiter.hide()

                })
                .catch((e)=>{
                    console.log('e', e)
                    if(e?.code === 403 && props.attrs.hideIfRestricted) {
                        spaceHidden.value = true
                    }
                    loader.footerLoader = false
                    if(e?.message){
                        Object.assign(error, e)
                    }
                    else{
                        console.log('e.code', e.code)
                        error.message = e.code!==undefined&&e.code===0?'Internet connection error':'Unknown error'
                      }
                })
        }

        function distributeFetchedData(){
            let limiter = (setting.loader?.type==='all' || props.attrs.loaderType==='all')?data.fetched.length:data.indexDefaultValue
            data.shown = data.fetched.slice(0, limiter)
        }

        if(!props.preFetchedData){
            fetchData() // fetch data only is no preFetchedData are provided
        }
        else{
            loader.footerLoader = false
        }

        function emitFetchedData(){
            if(props.attrs.singleRow){
                emit('dataFetched', data.original)
            }
            else{
                emit('dataFetched', {data})
            }
        }

        function update({row, update, setting, done, attrs}){
            let originalItem = utilities.copyObject(row) // preserve original item data
            Object.assign(row, update.value) // assign updating data to item

            capi.update({
                ...attrs,
                path: attrs.customPath?attrs.path:'update',
                id: row.id,
                table: setting.table
            }, update.value)
                .then((response)=>{
                    // remove solved alerts
                    Object.keys(update.value).forEach((property)=>{
                        if(row.alerts){
                            let alert = row.alerts[property]
                            if(alert) alert.solved = true
                        }
                    })

                    utilities.createRowAlerts(response.alerts, row)

                    // check if we updated any googleAuthority column
                    // todo use column prop
                    let updateHasGoogleAuthColumn = columns.filter(column => column['space_column_google_authority'] === 1).find(column => Object.keys(update.value).includes(column.property))
                    if(updateHasGoogleAuthColumn){
                        if(response.updated['hasFiles']){
                            driveService.relocateFiles({
                                ...attrs,
                                table: setting.table
                            }, response.updated.id)
                                .then(()=>emit('dataTouched')) // to reFetch files
                                .catch(dialog.nonCritical)
                        }
                        else if(setting.table === 'file'){
                            driveService.relocateFile({
                                ...attrs,
                                table: setting.table
                            }, response.updated.id)
                                .catch(dialog.nonCritical) // todo check if we must reassign file row, or not
                        }

                        // If table is project
                        if(setting.table === 'project' && response.updated['driveFolder']){
                            driveService.createOrUpdateProjectFolder({
                                ...attrs,
                                table: setting.table
                            }, response.updated.id)
                                .catch(dialog.nonCritical)
                        }

                        // If table is contact
                        if(setting.table === 'contact' && response.updated['driveFolder']){
                            driveService.updateContactFolder({
                                ...attrs,
                                table: setting.table
                            }, response.updated.id)
                                .catch(dialog.nonCritical)
                        }

                    }

                    return utilities.reloadConfigurationIfNeeded(response)
                })
                .then((response)=>{
                    columns.filter(column => column['space_column_do_not_assign_updated_value'] === 1)
                       .forEach(column=>delete response.updated[column.property])
                    //delete response.updated['timer_tracked_time'] // todo get the info from column
                    Object.assign(row, response.updated)
                    update.value = {} // must reset updateData
                    emit('dataTouched') // for File manager usage
                    if(done) done(response) // callback for emit
                })
                .catch((e)=>{
                    update.value = {} // must reset updateData
                    originalItem.focusedElement = null // todo check
                    Object.assign(row, originalItem) // revert item changes if error occurs
                    dialog.error(e)
                })
        }

        function addRow({addData, success, rError}){
            addWatcher.value = true
            error.message = null
            return capi.create({
                ...props.attrs,
                path: 'create'
                }, addData.value)
                .then(utilities.reloadConfigurationIfNeeded)
                .then((response)=>{
                    if(response.alerts) response.item.alerts = response.alerts
                    response.item.new = true
                    response.item.focused = true
                    utilities.data.addItem(data, response.item)
                    waiter.hide()
                    addData.value = {};
                    addWatcher.value = false
                    loader.noData = false
                    emit('dataTouched', response.item)
                    if(success) success(data.shown[0])
                    return data.shown[0]
                })
                .catch((e)=>{
                    addWatcher.value = false
                    if(rError){
                        rError(e) // todo dont know:D
                    }
                    else{
                        dialog.error(e)
                        console.log(e)
                    }
                })
        }

        /**
         * Reload one row
         * Do not move function to utilities as we must get attrs from this builder
         * @param row ref original objects
         * @return {Promise<unknown>}
         */
        function reloadRow(row){
            return capi.get({
                ...props.attrs,
                path: 'getOne',
                id: row.id,
                volume: env.volume
            })
                .then((item)=>{
                    return utilities.data.assignRowData(row, item)
                })
                .catch(dialog.nonCritical)
        }

        function useTemplate({row}){
            templateManager.loadingMessage = 'Creating transaction from template'
            return capi.create({
                ...props.attrs,
                path: 'useTemplate',
                id: row.id
            }, )
                .then((response)=>{
                    utilities.data.addItem(data, response.item)
                    waiter.hide()
                    addWatcher.value = false
                    loader.noData = false
                    templateManager.open = false
                    emit('dataTouched', response.item)
                    return data.shown[0]
                })
                .catch((e)=>{
                    dialog.error(e)
                })
        }

        function showTemplates(){
            templateManager.open = true
        }

        /**
         * Watch for searchData
         */
        watch((searchData), function(){
            searchAndSort()
        }, {deep: true})

        /**
         * Watch for sortData
         */
        watch((sortData), function(){
            searchAndSort()
        }, {deep: true})

        function clearSearch(){
            searchData.value = {}
        }

        function searchAndSort(){
            function callsearchAndSort(){
                return new Promise((resolve)=>{
                    data.index = data.indexDefaultValue
                    let searches = Object.entries(searchData.value).filter(([property, value])=>value!='')
                    if(searches.length || sortData.column.property){
                        let tempData;

                        if(searches.length){
                            tempData = data.original.filter(row=>{
                                return searches.every(function([property, value]) {
                                    // Every column type must be handled differently
                                    let column = allColumns.find(column => column.property === property)
                                    let rowValue = row[property]

                                    switch(column.dataType){
                                        case 'date':
                                            value = new Date(value).setHours(0,0)
                                            rowValue = new Date(rowValue).setHours(0,0)
                                            break;

                                        case 'datalist':
                                        case 'select':
                                        case 'number':
                                        case 'checkbox':
                                            break;

                                        case 'currency':
                                            if(rowValue) rowValue = Math.floor(Math.abs(Number(rowValue)))
                                            if(value) value = Math.abs(Number(value))
                                            break;

                                        default:
                                            if(rowValue) rowValue = utilities.sanitizeAccents(rowValue).toLowerCase()
                                            if(value) value = utilities.sanitizeAccents(value).toLowerCase()

                                    }

                                    switch(column.liveSearch){
                                        case 'starts-with':
                                        case 'startsWith':
                                            return rowValue && rowValue.startsWith(value)

                                        case 'contains':
                                            return rowValue && rowValue.includes(value)

                                        default:
                                            return rowValue==value
                                    }

                                })
                            })
                        }
                        else{
                            data.fetched = data.original // in case there ar no searches, revert fetched data to original
                        }

                        if(sortData.column.property&&sortData.type){
                            let seekInData = tempData?tempData:utilities.copyObject(data.fetched) // must copy objects as sort function keeps objects reference
                            tempData = seekInData.sort(function(a,b){
                                // Turn your strings into dates, and then subtract them
                                // to get a value that is either negative, positive, or zero.
                                let valueA = a[sortData.column.property]
                                let valueB = b[sortData.column.property]
                                switch(sortData.column.dataType){
                                    case 'date':
                                        valueA = new Date(valueA)
                                        valueB = new Date(valueB)
                                        break;
                                }

                                switch(sortData.column.dataType){
                                    case 'text':
                                        return sortData.type==='desc'?valueA.localeCompare(valueB):-1*valueA.localeCompare(valueB)

                                    default:
                                        return sortData.type==='desc'?valueB-valueA:valueA-valueB
                                }
                            });
                        }

                        data.fetched = tempData;
                        if(!data.fetched.length) {
                            loader.noData = true
                        }
                        else{
                            loader.noData = false
                            if(data.fetched.length<=data.indexDefaultValue) waiter.hide()
                        }
                        data.shown = (setting.loader?.type==='all' || props.attrs.loaderType==='all')?data.fetched:data.fetched.slice(0, data.indexDefaultValue)
                        loader.footerLoader = false
                        loader.noMoreData = false
                    }
                    else{
                        data.fetched = data.original
                        data.shown = (setting.loader?.type==='all' || props.attrs.loaderType==='all')?data.fetched:data.fetched.slice(0, data.indexDefaultValue)
                    }
                    resolve()
                })
            }

            callsearchAndSort()
                .then(()=>{
                    //emit('searchedAndSorted', data.fetched)
                    emitFetchedData()
                    //emit('dataFetched', {data})
                })
                .catch((e)=>{
                dialog.error('Error while searching or sorting')
            })

        }

        function showContextmenu(e, row){
            if((props.attrs.noContextMenu || props.attrs.singleRow) && !props.attrs.forceContextMenu ) return
            data.shown
                .filter(item => item.id!==row.id) // filter all rows except current row
                .forEach(item => item.selected = false) // deselect other rows

            let spaceContextMenu = env.configuration['menu_context'].filter((menu) => {
                if(props.attrs.contextMenuButtons){
                    return menu.space === props.attrs.space && props.attrs.contextMenuButtons.includes(menu['menu_context_id'])
                }else{
                    return menu.space === props.attrs.space
                }
            })

            if(spaceContextMenu.length){
                row.selected = true
                Object.assign(contextMenu, {
                    attrs: props.attrs,
                    setting,
                    row,
                    data,
                    position: {
                        top: e.pageY+'px',
                        left: e.pageX+'px'
                    },
                    handling: {},
                    open: true
                })
            }
        }

        /**
         * Load more grid data while scrolling
         */
        function loadMoreData(){
            if(data.shown.length < data.fetched.length){
                let span = setting.loader.span.further
                let sliced = data.fetched.slice(data.index, data.index+span)
                data.index = data.index+span
                data.shown.push(...sliced)
                if(data.shown.length >= data.fetched.length){
                    loader.footerLoader = false
                    loader.noMoreData = true
                }
            }
            else{
                loader.footerLoader = false
                loader.noMoreData = true
            }

        }

        function reFetch(removeOriginalFirst = false, data = null, fetchId = null, doNotSwapTimerRow = false){
            return fetchData(true, removeOriginalFirst, data, fetchId, doNotSwapTimerRow)
        }

        function addRowAndUploadFiles({attrs, row, addData, ...rest}){
            addRow({addData})
                .then((row)=>{
                    uploadFiles({ ...rest, row, attrs})
                })
        }

        function uploadFiles({setting, attrs, files, row = null}){
            run({method: setting.fileDrop, setting, attrs, row, files})
        }

        function catchRowClicked(e, item){
            emit('catchRowClicked', e, item)
        }

        function deselectAllRows(){
            utilities.deselectAllRows(data)
        }

        defineExpose({
            deselectAllRows,
            reloadRow,
            reFetch,
            update,
            methods,
            run
        })

        return {
            createContactGuide,
            run,
            methods,
            transactionManager,
            projectManager,
            accountManager,
            requestManager,
            update,
            contextMenu,
            showContextmenu,
            clearSearch,
            catchRowClicked,
            data,
            columns,
            setting,
            loadMoreData,
            addRow,
            searchData,
            sortData,
            addWatcher,
            fetchData,
            reFetch,
            loader,
            uploadFiles,
            showTemplates,
            templateManager,
            fileManager,
            commentManager,
            bankManager,
            useTemplate,
            closeCommentManager,
            spaceHeader,
            invoiceRequest,
            closeInvoiceRequest,
            spaceHidden,
            addRowAndUploadFiles,
            deselectAllRows,
            reloadRow,
            error,
            invoicingStatuses,
            invoicingDialog,
            closeInvoicingStatuses,
            incomeRequestManagerRef,
        }
    }
}
</script>

<style lang="scss">

@import "@/assets/scss/_btn.scss";

.space
{
    margin: 10px auto 20px;
    border-spacing: 0;
    text-align: left;

    tr{
        /*height: 30px;*/
    }

    th,
    td{
        height: 22px;
    }

    select,
    input,
    textarea{
        width: calc(100% - 3px);
        padding: 0;
        background-color: transparent;
        border: none;
        height: 20px;
        font-size: 11px;

        &[type='checkbox']
        {
            $dimension: 16px!important;
            height: $dimension;
            width: $dimension;
            margin: 0;
            accent-color: $goldOrange;
        }

        &:focus,
        &:focus-visible{
            outline-style: none!important;
            background-color: transparent!important;
            border-radius: 0!important;
            -webkit-background-clip: text;
        }
    }

    textarea{
        height: auto;
        padding: 3px;
    }

    option{
        background-color: $darkGray;
    }

    button{
        @extend .btn__gold;
        width: fit-content;
        height: 18px;
        font-size: 10px;
        padding: 1px 4px 2px;
    }

    thead{
        z-index: 3;
        top: 0;
        position: sticky;

        .add-bar{
            height: 27px;
        }

        .search-bar{
            height: 29px;
        }

        tr{
            height: 30px;

            &:first-of-type th{
                border-color: #f0f0f0;
            }

            &:last-of-type th{
                border-left-width: 0;
                border-bottom-width: 2px;
                border-bottom-style: solid;
                border-bottom-color: #3f3f3f;
            }

            th{
                padding-left: 3px;
                border: 1px dotted #3f3f3f;
                border-left-width: 0;
                border-bottom-width: 0;

                &:first-child{
                    border-left-width: 1px;
                    border-left-color: #f0f0f0;
                }

                &:last-child{
                    border-right-color: #f0f0f0;
                }

                .add-button{
                    @extend .btn__add;
                }

                .clear-button{
                    @extend .btn__clear;
                }
            }

        }


    }

    tbody {

        select,
        input,
        textarea{
            color: #f0f0f0;
        }

        /* no focus setting for select */
        input,
        textarea{
            &:focus,
            &:focus-visible{
                color: #f0f0f0;
                -webkit-text-fill-color: #f0f0f0;
            }
        }

        tr {
            z-index: 1;
            td{
                position: relative;
                border: 1px dotted #f0f0f0;
                padding: 3px;
                height: 18px;
                border-left-width: 0;
                border-bottom-width: 0;
                font-size: 11px;

                &:first-child{
                    border-left-width: 1px;
                }

                &.activate,
                &.selection{
                    cursor: pointer;
                }

                &.activated{
                    padding: 0 3px 0 0;
                    input {
                        padding-left: 3px;
                    }
                }

                &.toggle_time_tracker{
                    background-color: $lighterGray;
                }

                .progress{
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    //background-color: $lighterGray;
                    z-index: -1;

                    &.positive{
                        background-color: $green;
                    }

                    &.negative{
                        background-color: $red;
                    }
                }
            }

            &.selected td{
                background: rgba(0,0,0,0.5) !important;
                color: #f0f0f0!important;
                select{
                    background-color: transparent!important;
                }
                input{
                    color: #f0f0f0!important;
                }
            }

            &.success td{
                background-color: #00b050!important;
            }

            &.failed td{
                background-color: crimson!important;
            }

            &.new td{

            }

            &.dropping td{
                background-color: $darkOrange;
            }

            &:first-child td{
                border-top-width: 0;
            }

            &:last-child td{
                border-bottom-width: 1px;
            }

            &:hover td{
                background-color: rgba(0,0,0,0.5)!important;
                color: #f0f0f0!important;
                input,
                select,
                div:not(.tooltip){
                    background-color: transparent!important;
                    color: #f0f0f0!important;
                }
            }
        }

        .transaction_item,
        .item_name{
            background-color: $darkGray;
            input{
                background-color: transparent;
            }
        }

        &.with-no-space-header{
            tr{
                &:first-child td{
                    border-top-width: 1px;
                }
            }
        }



    }
    /* todo use it for search bar as well -> must add data-source and data-value to search select component */
    [data-source='invoice_status_type'][data-value='to-set'] {  background-color: gray; color: #ffffff;} /* K domluveni */
    [data-source='invoice_status_type'][data-value='set'] { background-color: #6b6b6b; color: #ffffff;} /* Domluveno */
    [data-source='invoice_status_type'][data-value='for-invoicing'] {  background-color: #e400e0; color: #ffffff;} /* K fakturaci */
    [data-source='invoice_status_type'][data-value='inquired'] {  background-color: #548dd4; color: #ffffff;} /* Osloveno */
    [data-source='invoice_status_type'][data-value='issued'] {  background-color: #ff7800; color: #ffffff;} /* Vystaveno */
    [data-source='invoice_status_type'][data-value='to-paid'] {  background-color: $red; color: #ffffff;} /* K uhrazeni */
    [data-source='invoice_status_type'][data-value='partly-paid'] {  background-color: gold; color: #3f3f3f;} /* Castecne uhrazeno */
    [data-source='invoice_status_type'][data-value='paid'] {  background-color: $green} /* Uhrazeno */
    [data-source='invoice_status_type'][data-value='distributed'] {  background-color: $green} /* Rozdeleno */
    [data-source='invoice_status_type'][data-value='not-distributed'] {  background-color: $green} /* Nerozdeleno */
    [data-source='invoice_status_type'][data-value='to-be-distributed'] {  background-color: #7329bf} /* K rozdeleni */
    [data-source='invoice_status_type'][data-value='overpaid'] {  background-color: lavender; color: #3f3f3f} /* Preplatek */
    [data-source='invoice_status_type'][data-value='waiting'] {  background-color: #3f3f3f; color: #ffffff} /* Ke zpracovani */
    [data-source='invoice_status_type'][data-value='stuck'] {  background-color: $red; color: #ffffff} /* Stuck */
    [data-source='invoice_status_type'][data-value='not-paid'] {  background-color: $red; color: #ffffff} /* Not paid */

    td[data-source='project_status'][data-value='1'] {  background-color: $green; color: $whiteGray} /* open */
    td[data-source='project_status'][data-value='2'] {  background-color: $lightenGray; color: $whiteGray} /* closed */

    [data-source='request_status'][data-value='pending'] { background-color: gold; color: #3f3f3f; }
    [data-source='request_status'][data-value='approved'] { background-color: $green; color: #ffffff; }

    [data-source='file_status'][data-value='ready-to-send'] { background-color: gold; color: #3f3f3f; }
    [data-source='file_status'][data-value='waiting-for-approval'] { background-color: gold; color: #3f3f3f; }
    [data-source='file_status'][data-value='cancelled'] { background-color: $red; color: #ffffff; }
    [data-source='file_status'][data-value='approved'] { background-color: $green; color: #ffffff; }

    /* General columns settings */

    .id,
    .vld,
    .active,
    .transaction_has_file,
    .transaction_has_comment,
    .project_has_file,
    .project_drive_folder_id,
    .transaction_payment_button,
    .project_is_permanent,
    .contact_is_vat_authority,
    .contact_factoring,
    .selector,
    .member_due_table,
    .file_is_issued,
    .project_id,
    .thread_close,
    .bank_account_sync,
    .file_open,
    .contact_account
    {
        text-align: center;
        padding: 1px 2px;
    }

    .id,
    .vld,
    .active,
    .transaction_payment_button,
    .project_is_permanent,
    .contact_is_vat_authority,
    .contact_factoring,
    .selector,
    .member_due_table,
    .file_is_issued,
    .project_id,
    .thread_close,
    .bank_account_sync
    {
        $width: 25px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_payment_button,
    .transaction_has_file,
    .transaction_has_comment,
    .project_has_file,
    .project_drive_folder_id,
    .contact_drive_folder_id
    {
        $width: 30px;
        @include assignValue(width max-width min-width, $width);
        text-align: center;
        button{
            width: 25px;
            margin: 0;
        }
    }

    .project_drive_folder_id,
    .contact_drive_folder_id{
        button{
            background-color: gold;
            color: $darkGray;

            &:hover{
                background-color: lighten(gold, 15);
                color: $darkGray;
            }
        }
    }

    .id{
        $width: 35px;
        @include assignValue(width max-width min-width, $width);
    }

    .date{
        $width: 60px;
        @include assignValue(width max-width min-width, $width);

        &.past{
            background-color: crimson;
        }

        &.close-future{
            background-color: gold;
            color: $lighterGray;

            input{
                color: $lighterGray;
            }
        }
    }

    .amount{
        text-align: right;
        padding-right: 3px;
        $width: 70px;
        @include assignValue(width max-width min-width, $width);
        input{
            padding-right: 0;
            padding-left: 0;
            text-align: right;
        }
    }

    .invoice_request_request_status_id{
        $width: 70px;
        @include assignValue(width max-width min-width, $width);
    }

    .selector{
        padding: 0;

        button{
            margin: 0;
            width: 100%;
            height: 100%;
            border-radius: 0;
            padding: 1px 0 3px;
            font-size: 20px;
            font-weight: normal;

            &:hover{
                color: #f0f0f0;
            }
        }
    }

    .negative{
        background-color: $red;
    }

    .positive{
        background-color: $green;
    }

}

</style>
